.content-container {
  margin: 0 auto;
  max-width: 800px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.content-container--wide {
  max-width: 1000px;
}
.content-container--full-width {
  max-width: 1280px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9jb250ZW50LWNvbnRhaW5lciIsInNvdXJjZXMiOlsiY29udGVudC1jb250YWluZXIuc2NzcyIsIi4uLy4uLy4uL25vZGVfbW9kdWxlcy9zYXRzLXVpLWxpYi90b2tlbnMvc3BhY2luZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBLGNDREU7RURFRixlQ0ZFO0VER0Y7O0FBRUE7RUFDRTs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnc2F0cy11aS1saWIvdG9rZW5zL3NwYWNpbmcnO1xuXG4uY29udGVudC1jb250YWluZXIge1xuICBtYXJnaW46IDAgYXV0bztcbiAgbWF4LXdpZHRoOiA4MDBweDtcbiAgcGFkZGluZy1sZWZ0OiBzcGFjaW5nLiRtO1xuICBwYWRkaW5nLXJpZ2h0OiBzcGFjaW5nLiRtO1xuICB3aWR0aDogMTAwJTsgLy8gTk9URTogVGhpcyBpcyBuZWVkZWQgZm9yIHJlbmRlcmluZyBpbnNpZGUgZmxleGJveCBjb250YWluZXJzXG5cbiAgJi0td2lkZSB7XG4gICAgbWF4LXdpZHRoOiAxMDAwcHg7XG4gIH1cblxuICAmLS1mdWxsLXdpZHRoIHtcbiAgICBtYXgtd2lkdGg6IDEyODBweDtcbiAgfVxufVxuIiwiLy8gaHR0cHM6Ly93d3cuZmlnbWEuY29tL2ZpbGUvV3pLQ3dSWTA5em40cnpSVmZZMFl2ZFJ0L3NhdHMtZHMtc3R5bGVzP25vZGUtaWQ9NDA4JTNBODVcbiR4eHM6IDRweDtcbiR4czogOHB4O1xuJHM6IDEycHg7XG4kbTogMTZweDtcbiRsOiAyNHB4O1xuJHhsOiAzMnB4O1xuJHh4bDogNjRweDtcbiR4eHhsOiAxMjhweDtcblxuJGhlcm86IDI1NnB4O1xuJG1pbmltdW0tdmlld3BvcnQtd2lkdGg6IDMyMHB4O1xuIl19 */