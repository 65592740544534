@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';

.edit-opening-hours-page {
  margin-bottom: spacing.$xxxl;

  &__text-wrapper {
    margin-bottom: spacing.$m;
  }

  &__header {
    margin-bottom: spacing.$s;
  }

  &__sub-text {
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    position: fixed;
    bottom: 0;
    background-color: lightmode.$background-surface-primary;
    padding: spacing.$m;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: spacing.$s;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__modal-list,
  &__modal-section {
    >*+* {
      margin-top: spacing.$m;
    }
  }

  &__modal-description {
    color: lightmode.$on-surface-secondary;
  }
}
