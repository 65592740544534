@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';

.layout {
  $breakpoint: 1400px;
  height: 100%;

  &__messages {
    position: fixed;
    max-width: 800px;
    width: 100%;
    top: spacing.$xl;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 spacing.$xs;
    z-index: 200;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__person {
    display: none;

    @media (min-width: $breakpoint) {
      display: flex;
      justify-content: center;
    }
  }

  &__person-icon {
    margin-right: spacing.$xs;
  }

  &__main {
    flex: 1;

    &--margin-top {
      margin-top: spacing.$l;
    }
  }

  &__banner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: spacing.$xxs;
    background-color: light.$ge-indicator-tags-attention-default;
    color: light.$on-ge-on-indicator-tags-attention-default;
    padding: spacing.$s;
  }
}
