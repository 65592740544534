@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/corner-radius';

.club-feature-section {
  &:not(:last-of-type) {
    border-bottom: 1px solid lightmode.$ui-border;
  }

  &__header {
    padding: spacing.$l;
  }

  &__toggle-wrapper {
    display: flex;
    gap: spacing.$xs;
    align-items: center;
  }

  &__toggle-description {
    color: lightmode.$on-surface-secondary;
  }

  &__toggle {
    border: 0;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: spacing.$l;

    &:hover {
      background-color: lightmode.$ui-tabs;
    }
  }

  &__accordion {
    display: none;
    padding: spacing.$l;

    &--is-open {
      display: block;
      border-top: 1px solid lightmode.$ui-border;
    }
  }
}
