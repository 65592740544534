@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/corner-radius';

.edit-opening-hours {
  $breakpoint: 400px;
  padding: spacing.$l;
  background-color: lightmode.$background-surface-primary;
  border-radius: corner-radius.$s;
  margin-bottom: spacing.$l;

  display: flex;
  flex-direction: column;
  gap: spacing.$s;

  @media (max-width: $breakpoint) {
    padding: spacing.$s;
  }

  &__remove {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date-selector {
    display: flex;
  }

  &__description {
    color: lightmode.$on-background-secondary;
  }

  &__times {
    border-top: 1px solid lightmode.$ui-border;
    padding-top: spacing.$m;
    margin-top: spacing.$s;
  }
}
