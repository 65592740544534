@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/corner-radius';

.navigation {
  $breakpointSmall: 500px;
  $navWidth: 320px;
  $block: &;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__refresh {
    display: flex;
    align-items: center;
    margin-right: spacing.$m;
  }

  &__dark-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: light.$fixed-background-primary-default;
    opacity: 0.2;
    z-index: 100;
    display: none;

    &--active {
      display: block;
    }
  }

  &__wrapper {
    position: relative;
    background-color: light.$surface-primary-default;
    z-index: 101;
  }

  &__button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    color: inherit;
    padding: spacing.$m spacing.$l;
  }

  &__icon {
    margin-right: spacing.$m;
  }

  &__content-wrapper {
    overflow-y: auto;
    background-color: light.$surface-primary-default;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: $navWidth;
    padding-bottom: spacing.$m;

    position: fixed;
    top: 0;
    left: 0;

    &.before-enter {
      transform: translateX(-100%);
      transition: transform 0.4s cubic-bezier(0.22, 0.57, 0.25, 1);
    }

    &.entering {
      transform: translateX(0);
    }

    &.before-leave {
      transform: translateX(0);
      transition: transform 0.4s cubic-bezier(0.22, 0.57, 0.25, 1);
    }

    &.leaving {
      transform: translateX(-100%);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: spacing.$xxs;
    margin: spacing.$m 0;
  }

  &__links {
    padding: 0 spacing.$m;
  }

  &__bottom {
    background-color: light.$surface-secondary-default;
    display: flex;
    flex-direction: column;
    padding: spacing.$l 0;
    margin: spacing.$m spacing.$m 0;
    border-radius: corner-radius.$s;
  }

  &__extra-links {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: spacing.$xs;
  }

  &__links-category {
    &:not(:last-child) {
      margin-bottom: spacing.$m;
    }
  }

  &__link-header {
    margin-bottom: spacing.$xs;
    padding: 0 spacing.$xs;
    color: light.$on-surface-primary-alternate;
  }

  &__link-text {
    display: flex;
    align-items: center;
    gap: spacing.$xs;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: spacing.$s;
    border-radius: corner-radius.$s;

    @media (hover: hover) {
      &:hover {
        background: light.$surface-primary-hover;
      }
    }

    &--active {
      background: light.$surface-primary-selected;

      #{$block}__link-icon {
        color: light.$ge-icons-primary;
      }
    }
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
    gap: spacing.$m;
  }

  &__log-out {
    margin: 0 auto;
  }
}
