@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/corner-radius';

.day {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__toggle {
    margin-top: spacing.$s;
  }

  &__hours-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
    margin-left: auto;
  }

  &__hour-wrapper {
    display: flex;
    align-items: center;
  }

  &__hour {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__state {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: spacing.$xxs;
    border: 1px solid lightmode.$ui-border;
    background-color: lightmode.$ui-tabs;
    color: lightmode.$on-cta-disabled;
    width: 200px;
    padding: spacing.$s;
    border-radius: corner-radius.$s;
  }
}
