@use 'sats-ui-lib/tokens/font-names';
@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/spacing';
@use 'sass:color';

.time-input {
  $block: &;
  $vertical-padding: 13px;
  $line-height: 1.25;

  &__label {
    margin-bottom: spacing.$xs;
    padding-left: spacing.$m;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }

  &__input {
    border: 1px solid lightmode.$ui-border;
    border-radius: 8px;
    padding: $vertical-padding spacing.$m;
    font-size: 16px;
    font-family: font-names.$brand, sans-serif;
    line-height: $line-height;
    background-color: lightmode.$background-secondary;
    color: lightmode.$on-surface-primary;
    width: 100%;
    box-sizing: border-box;

    &:focus {
      outline: 2px solid lightmode.$primary;
      outline-offset: -2px;
    }

    &[disabled] {
      background-color: lightmode.$ui-border;
      border-color: lightmode.$ui-border;
    }

    @at-root {
      #{$block}--error & {
        border-color: lightmode.$signal-error;
        background-color: color.mix(
          lightmode.$signal-error,
          white,
          7%
        ); // NOTE: Undocumented background color

        &[disabled] {
          background-color: lightmode.$ui-border;
        }
      }
    }
  }

  &__input[type='date'] {
    appearance: textfield;
    min-height: 46px;
  }
}
